import { Environment } from './model/environment.model';

export const environment: Environment = {
  production: false,
  apiUrl: 'https://apim-psp-backend-test.azure-api.net/api',
  keycloakUrl: 'https://test.log.wien/auth',
  keycloakRealm: 'logwien',
  keycloakClient: 'we-ikp',
  baseUrl: 'https://ikpfrontendtest.kinsta.cloud',
  staticContentUrl: 'https://static.wienenergie.at/projects/premiumserviceportal/config',
  isCookieConsentEnabled: true,
  piwikTagPropertyId: '7a869ec1-2d1e-4866-a58f-67de85df74e8',
  cookieConsentDataUrl: 'https://test-static.wienenergie.at/modules/cookie-consent/cookie-consent-data.json',
  mazePropertyId: 'a4ea12d0-bb0a-46cb-b905-cd2185580d6c',
};
